import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MemberProject } from 'src/app/_models/memberProject';
import { MemberProjectForUpdate } from 'src/app/_models/memberProjectForUpdate';
import { Project } from 'src/app/_models/project';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ProjectService } from 'src/app/_services/project.service';
import { EditMemberProjectComponent } from './edit-member-project/edit-member-project.component';

@Component({
  selector: 'app-member-projects-maintenance',
  templateUrl: './member-projects-maintenance.component.html',
  styleUrls: ['./member-projects-maintenance.component.css']
})
export class MemberProjectsMaintenanceComponent implements OnInit {

  project: Project;
  memberProjects: MemberProject[];

  private memberProjectForUpdate: MemberProjectForUpdate;

  constructor(private router : Router
    , private projectService: ProjectService
    , private alertify: AlertifyService
    , private modalService: NgbModal) { }

  ngOnInit() {

    var params = this.router.url.split('/');
    var param = params[params.length - 1];
    var projectId = parseInt(param);
    if (projectId.toString() == param) {
      this.projectService.getProject(projectId).subscribe(project => {
        this.project = project;
        this.projectService.getMemberProjectsById(this.project.id).subscribe(results => {
          this.memberProjects = results;
        }, error => {
          this.alertify.error(error);
        });
      }, error => {
        this.alertify.error(error);
      })
      
    } else {
      this.alertify.error("The value '" + param + "' is not a valid project id");
    }
  }

  public editMemberProject(memberProject: MemberProject) {
    if (memberProject != null) {
      this.memberProjectForUpdate = {
        id: memberProject.id,
        projectId: memberProject.project.id,
        memberId: memberProject.member?.id,
        emailAddress: memberProject.emailAddress,
        statusCode: memberProject.statusCode
      }
    } else {
      this.memberProjectForUpdate = {
        id: 0,
        projectId: this.project.id,
        memberId: null,
        emailAddress: null,
        statusCode: "PENDING"
      }
    }

    const modalRef = this.modalService.open(EditMemberProjectComponent);
    modalRef.componentInstance.memberProject = this.memberProjectForUpdate;
    modalRef.result.then((result: any) => {
      console.log(result);
      location.reload();
    }).catch((error) => {
      if (error != null) {
        this.alertify.error(error);
      }
    });
  }
}
