import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { environment } from 'src/environments/environment';
import { Member } from '../_models/member';
import { AlertifyService } from './alertify.service';
import { Router } from '@angular/router';
import { PasswordReset } from '../_models/passwordReset';
import { Login } from '../_models/login';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.apiUrl + 'auth/';
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  currentMember: Member;
  wishlistMemberId: number;
  photoUrl = new BehaviorSubject<string>('../../assets/user.png');
  currentPhotoUrl = this.photoUrl.asObservable();

  constructor(private http: HttpClient,
              private alertify: AlertifyService,
              private router: Router) { }

  login(model: any) {
    return this.http.post(this.baseUrl + 'login', model)
      .pipe(
        map((response: any) => {
          const login = response;
          if (login) {
            let isConfirmed = false;
            login.member.logins.map((l) => {
              if (l.isConfirmed) {
                isConfirmed = true;
              }
            });

            localStorage.setItem('token', login.token);
            localStorage.setItem('member', JSON.stringify(login.member));
            localStorage.setItem('isConfirmed', isConfirmed ? 'true' : 'false');
            this.decodedToken = this.jwtHelper.decodeToken(login.token);
            this.currentMember = login.member;
          }
        })
      );
  }

  register(model: any) {
    return this.http.post(this.baseUrl + 'register', model);
  }

  loggedIn() {
    const token = localStorage.getItem('token');
    return (!this.jwtHelper.isTokenExpired(token)) && this.decodedToken != null;
  }

  isConfirmed() {
    const token = localStorage.getItem('isConfirmed');
    return (token === 'true');
  }

  logOut() {
    localStorage.removeItem('member');
    localStorage.removeItem('token');
    localStorage.removeItem('isConfirmed');
    this.decodedToken = null;
    this.currentMember = null;
    this.alertify.message('Logged out');
    this.router.navigate(['/home']);
  }

  sendPasswordReset(email: string) {
    return this.http.post(this.baseUrl + 'reset/' + email, {});
  }

  getPasswordReset(key: string) {
    return this.http.get<PasswordReset>(this.baseUrl + 'reset/' + key);
  }

  resetPassword(model: any) {
    return this.http.post(this.baseUrl + 'reset', model);
  }

  getLogin(email: string, mode: string) {
    return this.http.get<Login>(this.baseUrl + 'login/' + mode + '/' + email);
  }

  sendLoginConfirmation(login: Login) {
    return this.http.post(this.baseUrl + 'sendLoginConfirmation', login);
  }

  confirmLogin(key: string) {
    return this.http.put(this.baseUrl + 'confirm/' + key, {});
  }

  confirmParentInvitationLogin(key: string) {
    return this.http.put(this.baseUrl + 'confirm/parent/' + key, {});
  }

  setConfirmLogin() {
    if (this.decodedToken != null) {
      localStorage.setItem('isConfirmed', 'true');
    }
  }

  deleteLogin(login: Login): Observable<Login> {
    return this.http.post<Login>(this.baseUrl + 'login/delete', login);
  }
}
