<div>
  <div><b>Project:</b> {{ this.project?.title}}
  </div>
  <table class='table'>
    <thead>
      <tr>
        <th>Member</th>
        <th>Status</th>
      </tr>
  </thead>
    <tbody>
      <tr *ngFor="let memberProject of this.memberProjects">
        <td (click)="this.editMemberProject(memberProject);" *ngIf="memberProject.member != null">{{ memberProject.member.knownAs}}</td>
        <td (click)="this.editMemberProject(memberProject);" *ngIf="memberProject.member == null">{{ memberProject.emailAddress}}</td>
        <td (click)="this.editMemberProject(memberProject);">{{ memberProject.statusCode}}</td>
      </tr>
      <tr>
        <td colspan="2">
          <button class="fa fa-success btn-block" (click)="this.editMemberProject(null);">New</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>