import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './_guards/auth.guard';
import { MemberEditComponent } from './members/member-edit/member-edit.component';
import { TicketListComponent } from './ticket-list/ticket-listcomponent';
import { MemberEditResolver } from './_resolvers/member-edit.resolver';
import { TicketListResolver } from './_resolvers/ticket-list.resolver';
import { ProjectMaintenanceResolver } from './_resolvers/project-maintenance.resolver';
import { PreventUnsavedChanges } from './_guards/prevent-unsaved-changes.guard';
import { LoginComponent } from './authentication/login/login.component';
import { PasswordComponent } from './authentication/password/password.component';
import { ConfirmComponent } from './authentication/confirm/confirm.component';
import { ProjectMaintenanceComponent } from './project-maintenance/project-maintenance.component';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { MemberProjectsMaintenanceComponent } from './project-maintenance/member-projects-maintenance/member-projects-maintenance.component';
import { MemberProjectsComponent } from './member-projects/member-projects.component';
import { MemberProjectsResolver } from './_resolvers/member-projects.resolver';
import { TimeCardComponent } from './time-card/time-card.component';

export const appRoutes: Routes = [
    { path: '', component: HomeComponent },
    {
        path: '',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        children: [
            // tslint:disable-next-line: max-line-length
            { path: 'member/edit/:id', component: MemberEditComponent, resolve: { member: MemberEditResolver }, canDeactivate: [PreventUnsavedChanges] },
            { path: 'project-maintenance', component: ProjectMaintenanceComponent, resolve: { member: ProjectMaintenanceResolver } },
            { path: 'memberProjectsMaintenance/:id', component: MemberProjectsMaintenanceComponent },
            { path: 'ticket-list'
                , component: TicketListComponent
                , resolve: { member: TicketListResolver }
                , data: { ticketStatus: null }
            },
            { path: 'member-projects', component: MemberProjectsComponent, resolve: { member: MemberProjectsResolver } },
            { path: 'timesheet', component: TimesheetComponent },
            { path: 'timecard'
                , component: TimeCardComponent
                , resolve: { member: TicketListResolver }
                , data: { ticketStatus: null }
            }
        ]
    },
    { path: 'login', component: LoginComponent },
    { path: 'confirm/:key', component: ConfirmComponent },
    { path: 'reset/:key', component: PasswordComponent },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];
