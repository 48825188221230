import { Component, Inject, OnInit } from '@angular/core';
import {  UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Ticket } from 'src/app/_models/ticket';
import { DatePipe } from '@angular/common';
import { TimeBlockForUpdate } from 'src/app/_models/timeBlockForUpdate';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { TimeBlockService } from 'src/app/_services/timeBlock.service';
import { map, Observable, of, startWith } from 'rxjs';
import { TicketService } from 'src/app/_services/ticket.service';

@Component({
  selector: 'app-timeblock',
  templateUrl: './timeblock.component.html',
  styleUrls: ['./timeblock.component.scss']
})
export class TimeblockComponent implements OnInit {
  public modalTitle: string;

  public currentDate: Date;
  public timeblock: TimeBlockForUpdate;
  public availableTickets: Ticket[] = [{ id: 0, title: 'Loading... ', project: null, member: null, linkNumber: null, estimatedHours: 0, completedHours: 0, isActive: true}];
  public filteredTickets: Observable<Ticket[]> = of(this.availableTickets);

  public editTimeblock: TimeBlockForUpdate;
  myForm: UntypedFormGroup;

  constructor(
    private alertify: AlertifyService,
    private fb: UntypedFormBuilder, 
    private dialogRef: MatDialogRef<TimeblockComponent>,
    private timeblockService: TimeBlockService, 
    private ticketService: TicketService,
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) data) { 
      this.modalTitle = data.title;
      this.currentDate = data.currentDate;
      this.timeblock = data.timeBlock;
    }

  ngOnInit() {
    this.editTimeblock = {
      id: this.timeblock.id,
      ticketId: this.timeblock.ticketId,
      ticket: this.timeblock.ticket,
      notes: this.timeblock.notes,
      startTime: this.datePipe.transform(this.timeblock?.startTime, 'yyyy-MM-dd HH:mm'),
      endTime: this.datePipe.transform(this.timeblock?.endTime, 'yyyy-MM-dd HH:mm')
    }

    this.myForm = this.fb.group({
      start: [this.editTimeblock.startTime, Validators.required],
      end: [this.editTimeblock.endTime, Validators.required],
      ticket: this.editTimeblock.ticket,
      notes: this.editTimeblock.notes,
    });

    this.loadTickets();
  }

  loadTickets() {
    this.ticketService.getTickets(true).subscribe(
      (tickets: Ticket[]) => this.handleFetchTicketsResponse(tickets),
      err => this.handleFetchTicketsError(err)
    );
  }

  private handleFetchTicketsResponse(tickets: Ticket[]) {
    this.availableTickets = tickets;
    this.filteredTickets = this.myForm.controls['ticket'].valueChanges.pipe(
      startWith(''),
      map(value => this.filterTicket(value))
    );  
  }

  private filterTicket(title: string): Ticket[] {
    let result = this.availableTickets.filter(aTicket => aTicket.title.toLowerCase().includes(title.toLowerCase())); 
    return result;
  }

  private handleFetchTicketsError(err: Error) {
    this.alertify.error(err.message);
    console.log(err);
  }

  public getOptionText(option) {
    if (option == null)
      return '';
    return option.title;
  }

  save(form: UntypedFormGroup) {
    if (form.valid) {
      this.editTimeblock.startTime = this.datePipe.transform(form.value.start, 'yyyy-MM-dd HH:mm');
      this.editTimeblock.endTime = this.datePipe.transform(form.value.end, 'yyyy-MM-dd HH:mm');
      this.editTimeblock.ticketId = form.value.ticket.id;
      this.editTimeblock.notes = form.value.notes;
      this.timeblockService.updateTimeBlock(this.editTimeblock).subscribe(result => {
        this.dialogRef.close(result.timeblock);
      });
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  delete() {
    this.timeblockService.deleteTimeBlock(this.editTimeblock.id).subscribe(result => {
      this.dialogRef.close(this.timeblock);
    }, error => {
      this.alertify.error('Could not delete TimeBlock ' + this.editTimeblock.id);
    });

  }
}
