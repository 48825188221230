import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectForUpdate } from 'src/app/_models/projectForUpdate';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ProjectService } from 'src/app/_services/project.service';

@Component({
  selector: 'app-project',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.css']
})
export class ProjectEditComponent implements OnInit {
  @Input() public project: ProjectForUpdate;

  public editProject: ProjectForUpdate;
  public modalTitle: string = "Edit Project";
  public myForm: UntypedFormGroup;

  constructor(public activeModal: NgbActiveModal,
    private alertify: AlertifyService,
    private fb: UntypedFormBuilder, 
    private projectService: ProjectService) { }

  ngOnInit() {
    this.editProject = {
      id: this.project.id,
      subscriptionId: this.project.subscriptionId,
      code: this.project.code,
      title: this.project.title,
      url: this.project.url,
      isActive: this.project.isActive
    };
    
    this.myForm = this.fb.group({
      code: [this.editProject.code, Validators.required],
      title: [this.editProject.title, Validators.required],
      url: this.editProject.url
    });
  }

  cancel() {
    this.activeModal.dismiss();
  }

  showMembers() {
    this.activeModal.close("memberProjectsMaintenance/" + this.editProject.id);
  }

  onSubmit(form: UntypedFormGroup) {
    if (form.valid) {
      this.editProject.code = form.value.code;
      this.editProject.title = form.value.title;
      this.editProject.url = form.value.url;

      this.projectService.updateTicket(this.editProject).subscribe(result => {
        this.activeModal.close(result);
      });
    }
  }

  activate(active: boolean) {
    this.projectService.activateProject(this.editProject.id, active).subscribe(project => {
      this.activeModal.close(project);
    }, error => {
      this.alertify.error(error);
    });
  }
}
