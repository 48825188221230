import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private authService: AuthService, private router: Router, private alertify: AlertifyService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.loggedIn && this.authService.decodedToken != null) {
      return true;
    }

    this.alertify.error('You must sign in!');
    this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
    return false;
  }

}
