import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subscription } from '../_models/subscription';
import { TimeBlock } from '../_models/timeBlock';
import { TimeBlockQuery } from '../_models/timeBlockQuery';
import { Member } from '../_models/member';

@Injectable({
    providedIn: 'root'
  })
  export class SubscriptionService {
    baseUrl = environment.apiUrl;
    constructor(private http: HttpClient) { }

    getMySubscriptions(): Observable<Subscription[]> {
      let url = this.baseUrl + 'subscription';
      return this.http.get<Subscription[]>(url);
    }

    getSubscriptionMembers(): Observable<Member[]> {
      let url = this.baseUrl + 'subscription/members';
      return this.http.get<Member[]>(url);
    }
  }