import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Project } from '../_models/project';
import { ProjectForUpdate } from '../_models/projectForUpdate';
import { MemberProject } from '../_models/memberProject';
import { MemberProjectForUpdate } from '../_models/memberProjectForUpdate';
import { MemberProjectStatus } from '../_models/memberProjectStatus';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getProjectStatus(): MemberProjectStatus[] {
    var result = [
      { statusCode: 'PENDING', statusDisplay: 'Pending', for: "owner" },
      { statusCode: 'ACCEPTED', statusDisplay: 'Accepted', for: "user" },
      { statusCode: 'REJECTED', statusDisplay: 'Rejected', for: "user" },
      { statusCode: 'WITHDRAWN', statusDisplay: 'Withdrawn', for: "owner" }
    ];

    return result;
  }
  
  getMyProjects(active: boolean): Observable<Project[]> {
    let url = this.baseUrl + 'project/status/' + (active == true?'true':'false');
    return this.http.get<Project[]>(url);
  }

  getProject(projectId: number) {
    let url = this.baseUrl + 'project/' + projectId;
    return this.http.get<Project>(url);
  }

  getMemberProjects(): Observable<MemberProject[]> {
    let url = this.baseUrl + 'project/memberProjects';
    return this.http.get<MemberProject[]>(url);
  }

  getMemberProjectsById(projectId: number): Observable<MemberProject[]> {
    let url = (this.baseUrl + 'project/memberProjects/' + projectId);
    return this.http.get<MemberProject[]>(url);
  }

  getSubscriptionProjects(active: boolean): Observable<Project[]> {
    let url = (this.baseUrl + 'project/subscriptionStatus/'+ (active == true?'true':'false'));
    return this.http.get<Project[]>(url);
  }

  updateTicket(project: ProjectForUpdate): Observable<Project> {
    let url = this.baseUrl + 'project';
    return this.http.put<Project>(url, project);
  }

  activateProject(projectId: number, value: boolean): Observable<Project> {
    let url = this.baseUrl + 'project';
    return this.http.put<Project>(url, { id: projectId, isActive: value });
  }

  updateMemberProject(memberProject: MemberProjectForUpdate): Observable<MemberProject> {
    let url = this.baseUrl + 'project/memberProject';
    return this.http.put<MemberProject>(url, memberProject);
  }
}
