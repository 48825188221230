import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { Project } from '../_models/project';
import { ProjectService } from '../_services/project.service';

@Injectable()
export class ProjectMaintenanceResolver  {
    constructor(private projectService: ProjectService,
                private authService: AuthService,
                private router: Router,
                private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Project[]> {
        return this.projectService.getSubscriptionProjects(true).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving your projects');
                console.log(error);
                //this.router.navigate(['home']);
                return of(null);
            })
        );
    }
}
