import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

import { TicketService } from '../_services/ticket.service';
import { ProjectService } from '../_services/project.service';
import { AlertifyService } from '../_services/alertify.service';

import { Ticket } from '../_models/ticket';
import { Project } from '../_models/project';
import { TicketForUpdate } from '../_models/ticketForUpdate';

import { EditTicketComponent } from './edit-ticket/edit-ticket.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnInit {

  public tickets: Ticket[];
  public projects: Project[];

  public newTicket: Ticket = {id: 0, project: null, title:'', linkNumber: '', member: null, estimatedHours: 0, completedHours: 0, isActive: true }
  private ticketForUpdate: TicketForUpdate;
  
  public myForm: FormGroup;
  public filterStatus:string = "active";
  public filterTitle:string = null;
  public filterProject:string = null;
  public filterLink:string = null;
  
  constructor(private route: ActivatedRoute
    , private ticketService: TicketService
    , private projectService: ProjectService
    , private dialog: MatDialog
    , private alertify: AlertifyService
    , private fb: FormBuilder) 
  { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.tickets = data.member;
    });

    this.projectService.getMyProjects(true).subscribe(projects => {
      this.projects = projects;
    }, error => {
      console.log(error);
    });

    this.myForm = this.fb.group({
      status: [this.filterStatus],
      title: [this.filterTitle],
      project: [this.filterProject],
      link: [this.filterLink]
    });
  }

  deactivateTicket(ticket: Ticket) {
    this.ticketService.activateTicket(ticket.id, false).subscribe(ticket => {
      console.log(ticket.id);
      this.tickets.splice(this.tickets.findIndex(t => t.id === ticket.id), 1);
    }, error => {
      this.alertify.error("Could not deactivate ticket id " + ticket.id) + ' because ' + error;
    })
  }

  activateTicket(ticket: Ticket) {
    this.ticketService.activateTicket(ticket.id, true).subscribe(ticket => {
      console.log(ticket.id);
      this.tickets.splice(this.tickets.findIndex(t => t.id === ticket.id), 1);
    }, error => {
      this.alertify.error("Could not activate ticket id " + ticket.id);
    })
  }

  getTickets() {
    this.ticketService.getTickets(null).subscribe(tickets => {
      this.tickets = tickets;
    });
  }

  public editTicket(ticket: Ticket) {
    if (ticket != null) {
      this.ticketForUpdate = {
        id: ticket.id,
        projectId: ticket.project?.id,
        title: ticket.title,
        linkNumber: ticket.linkNumber,
        estimatedHours: ticket.estimatedHours,
        isActive: ticket.isActive
      }
    } else {
      this.ticketForUpdate = {
        id: 0,
        projectId: 0,
        title: '',
        linkNumber: '',
        estimatedHours: 0,
        isActive: true
      }
    }
    // const modalRef = this.modalService.open(EditTicketComponent);
    // modalRef.componentInstance.ticket = this.ticketForUpdate;
    // modalRef.componentInstance.projects = this.projects;
    // modalRef.result.then((updatedTicket: Ticket) => {
    //   console.log(updatedTicket);
    //   location.reload();
    // }).catch((error) => {
    //   if (error != null) {
    //     this.alertify.error(error);
    //   }
    // });
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    let ticketTitle = ticket == null ? "Create Ticket" : "Edit " + ticket.title;
    dialogConfig.data = {
      id: 1,
      title: ticketTitle,
      ticket: this.ticketForUpdate,
      projects: this.projects
    };

    //https://blog.angular-university.io/angular-material-dialog/
    const dialogRef = this.dialog.open(EditTicketComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
        data => {
          console.log("Dialog output:", data);
          location.reload();
        }
    );
  }

  public onFilter(form: FormGroup) {
    this.filterStatus = form.value.status;
    this.filterTitle = form.value.title;
    this.filterProject = form.value.project;
    this.filterLink = form.value.link;
  }
}
