<div>
  <h1>My Tickets</h1>
  <form [formGroup]="this.myForm">
    <table class='table' width="100%" style="display: block; overflow: scroll; height: 100%;">
      <thead>
        <tr>
          <th>
            Project:
            <input formControlName="project" class="mat-form-field" placeholder="" (input)="onFilter(this.myForm)">
          </th>
          <th>
            Title:
            <input formControlName="title" class="mat-form-field" placeholder="" (input)="onFilter(this.myForm)">
          </th>
          <th>
            Link:
            <input formControlName="link" class="mat-form-field" placeholder="" (input)="onFilter(this.myForm)">
          </th>
          <th>
            Active:
            <select formControlName="status" class="mat-form-field" (change)="onFilter(this.myForm)">
              <option [value]="'active'">Active</option>
              <option [value]="'inactive'">Inactive</option>
              <option [value]="'all'">All</option>
            </select>
          </th>
          <th>Hours</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ticket of this.tickets | filterTickets: this.filterStatus: this.filterProject: this.filterTitle: this.filterLink">
          <td (click)="this.editTicket(ticket);">{{ ticket.project.title}} ({{ ticket.project.code}})</td>
          <td (click)="this.editTicket(ticket);">{{ ticket.title }}</td>
          <td *ngIf="!ticket?.linkNumber > '' || !ticket?.project.url > ''" (click)="this.editTicket(ticket);">No Link</td>
          <td *ngIf="ticket?.linkNumber > ''">
            <a target="_blank" href='{{ ticket.project.url.replace("[linkNumber]", ticket.linkNumber)}}'>
              {{ ticket.linkNumber }}</a>
          </td>
          <td (click)="this.editTicket(ticket);">{{ ticket.isActive }}</td>
          <td (click)="this.editTicket(ticket);">{{ ticket.estimatedHours}} / {{ ticket.completedHours }}</td>
        </tr>
        <tr>
          <td colspan="5">
            <button class="fa fa-success btn-block" (click)="this.editTicket(null);">New</button>
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</div>