import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';
import { Router } from '@angular/router';
import { Login } from '../_models/login';
import { LoginComponent } from '../authentication/login/login.component';
import { environment } from '../../environments/environment';
import { SubscriptionService } from '../_services/subscription.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  photoUrl: string;
  hasSubscriptions: boolean = false;

  public backgroundColor: string;

  constructor(public authService: AuthService
    , private subscriptionService: SubscriptionService
    , private alertify: AlertifyService
    , private router: Router) { }

  ngOnInit() {
    this.backgroundColor = (environment.production)?"bg-primary":"bg-danger";
    this.authService.currentPhotoUrl.subscribe(photoUrl => this.photoUrl = photoUrl);
    this.subscriptionService.getMySubscriptions().subscribe(subscriptions => {
      this.hasSubscriptions = subscriptions.length > 0;
      console.log(this.hasSubscriptions);
    });
  }

  login() {
    this.router.navigate(['login']);
  }

  loggedIn() {
    return this.authService.loggedIn();
  }

  logOut() {
    this.authService.logOut();
  }

  waitingForConfirmation() {
    this.alertify.confirm('Check your email for a confirmation link. Do you need a new link sent?', () => {
      let loginFound = false;
      this.authService.currentMember.logins.map((login) => {
        if (!loginFound && login.source === 'email') {
          this.sendLoginConfirmation(login);
          loginFound = true;
        }
      });

      if (!loginFound) {
        this.alertify.error('Could not locate your login');
      }
    });
    return false;
  }

  sendLoginConfirmation(login: Login) {
    this.authService.sendLoginConfirmation(login).subscribe(() => {
      this.alertify.success('Confirmation email successfully sent to ' + login.email);
    }, error => {
      this.alertify.error(error);
    });
    return false;
  }

  explainMembersOnly() {
    this.alertify.message('You must log in to access the non-public areas of the website.');
  }

  getRouter() {
    return this.router;
  }
}
