import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Project } from 'src/app/_models/project';
import { TicketForUpdate } from 'src/app/_models/ticketForUpdate';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { TicketService } from 'src/app/_services/ticket.service';

@Component({
  selector: 'app-ticket',
  templateUrl: './edit-ticket.component.html',
  styleUrls: ['./edit-ticket.component.css']
})
export class EditTicketComponent implements OnInit {
  public ticket: TicketForUpdate;
  public projects: Project[];
  
  public editTicket: TicketForUpdate;
  public modalTitle: string = "Edit Ticket";
  public myForm: UntypedFormGroup;

  constructor(
    private alertify: AlertifyService,
    private dialogRef: MatDialogRef<EditTicketComponent>,
    private fb: UntypedFormBuilder, 
    private ticketService: TicketService,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.modalTitle = data.title;
    this.ticket = data.ticket;
    this.projects = data.projects;
  }

  ngOnInit() {
    this.editTicket = {
      id: this.ticket.id,
      projectId: this.ticket.projectId,
      title: this.ticket.title,
      estimatedHours: this.ticket.estimatedHours,
      linkNumber: this.ticket.linkNumber,
      isActive: this.ticket.isActive
    };
    
    this.myForm = this.fb.group({
      project: [this.editTicket.projectId, Validators.required],
      title: [this.editTicket.title, Validators.required],
      estimatedHours: this.editTicket.estimatedHours,
      linkNumber: this.editTicket.linkNumber
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  onSubmit(form: UntypedFormGroup) {
    if (form.valid) {
      this.editTicket.projectId = form.value.project;
      this.editTicket.title = form.value.title;
      this.editTicket.estimatedHours = form.value.estimatedHours;
      this.editTicket.linkNumber = form.value.linkNumber;

      this.ticketService.updateTicket(this.editTicket).subscribe(result => {
        this.dialogRef.close(result);
      });
    }
  }

  activate(isActive: boolean) {
    this.ticketService.activateTicket(this.editTicket.id, isActive).subscribe(ticket => {
      this.dialogRef.close(ticket);
    }, error => {
      this.alertify.error("Could not " + isActive? "activate": "deactivate" + " ticket id " + this.editTicket.id);
    })
  }
}
