import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule, MatDialog } from "@angular/material/dialog";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInput } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";

import { SortPipe } from 'src/app/_pipes/sortPipe';
import { FilterPipe } from 'src/app/_pipes/filterPipe';
import { ExcludePipe } from 'src/app/_pipes/excludePipe';
import { NotNullPipe } from 'src/app/_pipes/notNullPipe';
import { FilterWildPipe } from 'src/app/_pipes/filterWildPipe';
import { FilterProjectsPipe } from '../_pipes/filterProjectsPipe';
import { FilterTicketsPipe } from '../_pipes/filterTicketsPipe';

@NgModule({
  declarations: [SortPipe, FilterPipe, ExcludePipe, NotNullPipe, FilterWildPipe, FilterProjectsPipe, FilterTicketsPipe],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    MatSliderModule, MatCheckboxModule, 
    MatDialogModule,
    MatAutocompleteModule, MatInput, MatSelectModule
  ],
  exports: [
    FormsModule, ReactiveFormsModule,
    SortPipe, FilterPipe, ExcludePipe, NotNullPipe, FilterWildPipe, FilterProjectsPipe, FilterTicketsPipe,
    MatSliderModule, MatCheckboxModule,
    MatDialogModule,
    MatAutocompleteModule, MatInput, MatSelectModule
  ]
})
export class SharedModule { }
