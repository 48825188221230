import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Project } from '../_models/project';
import { ProjectService } from '../_services/project.service';
import { ProjectForUpdate } from '../_models/projectForUpdate';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertifyService } from '../_services/alertify.service';
import { ProjectEditComponent } from './project-edit/project-edit.component';
import { SubscriptionService } from '../_services/subscription.service';

@Component({
  selector: 'app-project',
  templateUrl: './project-maintenance.component.html',
  styleUrls: ['./project-maintenance.component.scss']
})
export class ProjectMaintenanceComponent implements OnInit {

  ownedProjects: Project[];
  private projectForUpdate: ProjectForUpdate;
  public showActive: boolean = true;
  public subscriptionId: number = 0;

  constructor(private route: ActivatedRoute
    , private modalService: NgbModal
    , private projectService: ProjectService
    , private subscriptionService: SubscriptionService
    , private alertify: AlertifyService) { }

  ngOnInit() {
    console.log('Get Subscription/Projects');
    this.route.data.subscribe(data => {
      this.ownedProjects = data.member;
    });
    this.subscriptionService.getMySubscriptions().subscribe(subscriptions => {
      if (subscriptions.length > 0) {
        this.subscriptionId = subscriptions[0].id;
      }
    });
  }

  getSubscriptionProjects() {
    console.log(this.showActive);
    this.projectService.getSubscriptionProjects(this.showActive).subscribe(projects => {
      this.ownedProjects = projects;
    });
  }
  
  public editProject(project: Project) {
    if (project != null) {
      this.projectForUpdate = {
        id: project.id,
        subscriptionId: project.subscription?.id,
        code: project.code,
        title: project.title,
        url: project.url,
        isActive: project.isActive
      }
    } else {
      this.projectForUpdate = {
        id: 0,
        subscriptionId: this.subscriptionId,
        code: '',
        title: '',
        url: '',
        isActive: true
      }
    }
    const modalRef = this.modalService.open(ProjectEditComponent);
    modalRef.componentInstance.project = this.projectForUpdate;
    modalRef.result.then((result: any) => {
      console.log(result);
      if (result.id) {
        location.reload();
      } else {
        window.open(result, "_blank");
      }
    }).catch((error) => {
      if (error != null) {
        this.alertify.error(error);
      }
    });
  }
}
