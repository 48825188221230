import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TimeBlock } from '../_models/timeBlock';
import { Observable } from 'rxjs';
import { TimeBlockForUpdate } from '../_models/timeBlockForUpdate';
import { TimeBlockResults } from '../_models/timeBlockResults';
import { TimeBlockQuery } from '../_models/timeBlockQuery';

@Injectable({
  providedIn: 'root'
})
export class TimeBlockService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  updateTimeBlock(timeBlock: TimeBlockForUpdate) {
    let url = this.baseUrl + 'timesheet';
    return this.http.put<TimeBlockResults>(url, timeBlock);
  }

  getTimeBlocks(query: TimeBlockQuery): Observable<TimeBlock[]> {
    let url = this.baseUrl + 'timesheet/timeblocks';
    return this.http.put<TimeBlock[]>(url, query);
  }

  deleteTimeBlock(timeBlockId: number) {
    let url = this.baseUrl + 'timesheet/delete/' + timeBlockId;
    return this.http.get(url);
  }
}
