import { Pipe, PipeTransform } from '@angular/core';
import { Ticket } from '../_models/ticket';

@Pipe({
    name: 'filterTickets',
    pure: false
})
export class FilterTicketsPipe implements PipeTransform {
    transform(items: Ticket[], status: string, project: string, title: string, link: string): any[] {
        if (!items) { return []; }
        return items.filter(it => 
            (
                status == "all" 
                || (it.isActive && status == "active")
                || (!it.isActive && status == "inactive")
            ) 
            && (!title 
                || (it.title != null && it.title.toLowerCase().includes(title.toLowerCase()))
            )
            && (!project 
                || (it.project != null && it.project.code.toLowerCase().includes(project.toLowerCase()) 
                    || it.project.title.toLowerCase().includes(project.toLowerCase())
                )
            )
            && (!link 
                || (it.linkNumber != null && it.linkNumber.toLowerCase().includes(link.toLowerCase()))
                || ((!it.linkNumber || !it.project || !it.project.url) && link.toLowerCase() == "no link") 
            )
        );

    }
}
