<div>
  <h1>My Subscriptions</h1>
  <div *ngIf="this.subscriptionId > 0" >
    <div>Show Active: 
      <input type="checkbox" [(ngModel)]="this.showActive" [checked]="true" (change)="getSubscriptionProjects()">
    </div>
    <table  class='table' width="100%" style="display: block; overflow: scroll; height: 100%;">
      <thead>
        <tr>
          <th colspan="3">
            Subscription # {{ this.subscriptionId }}
          </th>
        </tr>
        <tr>
          <th>Title</th>
          <th>Code</th>
          <th>Url</th>
        </tr>
    </thead>
      <tbody>
        <tr *ngFor="let project of this.ownedProjects">
          <td (click)="this.editProject(project);">{{ project.title}}</td>
          <td (click)="this.editProject(project);">{{ project.code}}</td>
          <td (click)="this.editProject(project);">{{ project.url}}</td>
        </tr>
        <tr>
          <td colspan="3">
            <button *ngIf="this.showActive" class="fa fa-success btn-block" (click)="this.editProject(null);">New</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>