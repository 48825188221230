<div class="modal-header">
  <button type="button" class="fa fa-close" aria-label="Close"
    (click)="cancel()">
 </button>  
  <h4 class="modal-title">{{ this.modalTitle }}</h4>
</div>
<div class="modal-body">
  <form [formGroup]="this.myForm" (ngSubmit)="onSubmit(myForm)">
    <div *ngIf="this.editMemberProject.id > 0">
      <label style='width:100%'>
        Status:
        <select formControlName="statusCode" style='width:100%'>
          <option *ngFor="let status of this.statusList" [value]="status.statusCode">
            {{ status.statusDisplay }}
         </option>
        </select>
      </label>
      <div *ngIf="myForm.get('statusCode').invalid && (myForm.get('statusCode').dirty || myForm.get('statusCode').touched)">
        Please select a Status.
      </div>
    </div>
    <div *ngIf="this.editMemberProject.id == 0">
      <label style='width:100%'>
        Status:
        {{ "Pending" }}
      </label>
    </div>
    <div *ngIf="this.editMemberProject.id > 0">
      <label style='width:100%'>
        Email Address:
        {{ this.editMemberProject.emailAddress }}
      </label>
    </div>
    <div *ngIf="this.editMemberProject.id == 0">
      <label style='width:100%'>
        Email Address:
        <input formControlName="emailAddress" placeholder="" style='width:100%'>
      </label>
      <div *ngIf="myForm.get('emailAddress').invalid && (myForm.get('emailAddress').dirty || myForm.get('emailAddress').touched)">
        Please enter an email address.
      </div>
    </div>
    <button type="button" (click)="this.cancel();">Cancel</button>
    <button type="submit" [disabled]="myForm.invalid">Save</button>
  </form>  
</div>
