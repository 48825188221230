<div>
  <h1>My Projects</h1>
  <form [formGroup]="this.myForm">  
    <table  class='table' width="100%" style="display: block; overflow: scroll; height: 100%;">
      <thead>
        <tr>
          <th>
            Status:
            <select formControlName="status" class="mat-form-field" (change)="onFilter(this.myForm)">
              <option *ngFor="let status of this.statuses" [value]="status.statusCode">
                {{ status.statusDisplay }}
              </option>
            </select>
          </th>
          <th>
            Title:
            <input formControlName="title" class="mat-form-field" placeholder="" (input)="onFilter(this.myForm)">
          </th>
          <th>
            Code:
            <input formControlName="code" class="mat-form-field" placeholder="" (input)="onFilter(this.myForm)">
          </th>
          <th>
            Url:
            <input formControlName="url" class="mat-form-field" placeholder="" (input)="onFilter(this.myForm)">
          </th>
        </tr>
    </thead>
      <tbody>
        <tr *ngFor="let memberProject of this.memberProjects | filterProjects: this.filterStatus: this.filterTitle: this.filterCode: this.filterUrl">
          <td (click)="this.editMemberProject(memberProject);">{{ memberProject.statusCode}}</td>
          <td (click)="this.editMemberProject(memberProject);">{{ memberProject.project.title}}</td>
          <td (click)="this.editMemberProject(memberProject);">{{ memberProject.project.code}}</td>
          <td (click)="this.editMemberProject(memberProject);">{{ memberProject.project.url}}</td>
        </tr>
      </tbody>
    </table>
  </form>
</div>