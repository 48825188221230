<div class="modal-header">
  <button type="button" class="fa fa-close" aria-label="Close"
    (click)="cancel()">
 </button>  
 <h4 class="modal-title">{{ this.modalTitle }}</h4>
</div>
<div class="modal-body">
  <div [formGroup]="myForm">
    <div>
      <label style='width:50%'>
        Start Time:
        <timepicker formControlName="start" [minuteStep]="15"></timepicker>
      </label>
      <div *ngIf="myForm.get('start').invalid && (myForm.get('start').dirty || myForm.get('start').touched)">
        Please select a Start Time.
      </div>
      <label style='width:50%'>
        End Time:
        <timepicker formControlName="end" [minuteStep]="15"></timepicker>
      </label>
      <div *ngIf="myForm.get('end').invalid && (myForm.get('end').dirty || myForm.get('end').touched)">
        Please select an End Time.
      </div>
    </div>
    <div>
      <label style='width:100%'>
        Ticket:
        <mat-form-field appearance="fill" class="no-label-field" fxFlex="30" fxFlex.lt-md="100">
          <input type="text"
                 placeholder="Select or enter ticket title"
                 matInput
                 formControlName="ticket"
                 [matAutocomplete]="auto">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="this.getOptionText">
            <mat-option *ngFor="let ticket of filteredTickets | async" [value]="ticket">
              {{ ticket.title }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </label>
      <div *ngIf="myForm.get('notes').invalid && (myForm.get('notes').dirty || myForm.get('notes').touched)">
        Messages must be at least 5 characters long.
      </div>
    </div>
    <div>
      <label style='width:100%'>
        Notes:
        <input formControlName="notes" placeholder="Either Ticket or Notes is required - but both are preferred" class="form-control">
      </label>
      <div *ngIf="myForm.get('notes').invalid && (myForm.get('notes').dirty || myForm.get('notes').touched)">
        Messages must be at least 5 characters long.
      </div>
    </div>
    <button type="button" (click)="this.cancel();">Cancel</button>
    <button type="submit" [disabled]="myForm.invalid" (click)="this.save(myForm)">Save</button>
    <button *ngIf="this.editTimeblock.id > 0" type="button" (click)="this.delete();">Delete</button>
  </div>  
</div>