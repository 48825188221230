<div class="modal-header">
  <button type="button" class="fa fa-close" aria-label="Close"
    (click)="cancel()">
  </button>  
  <h4 class="modal-title">{{ this.modalTitle }}</h4>
</div>
<div class="modal-body">
  <form [formGroup]="this.myForm" (ngSubmit)="onSubmit(myForm)">
    <label style='width:100%'>
      Status:
      <select formControlName="statusCode" class="form-control">
        <option *ngFor="let status of this.statusList" [value]="status.statusCode">
          {{ status.statusDisplay }}
        </option>
      </select>
    </label>
    <div *ngIf="myForm.get('statusCode').invalid && (myForm.get('statusCode').dirty || myForm.get('statusCode').touched)">
      Please select a Status.
    </div>
    <label style='width:100%'>
      Email Address:
      {{ this.editMemberProject.emailAddress }}
    </label>
    
    <button type="button" (click)="this.cancel();">Cancel</button>
    <button type="submit" [disabled]="myForm.invalid">Save</button>
  </form>  
</div>
