import { Pipe, PipeTransform } from '@angular/core';
import { MemberProject } from '../_models/memberProject';

@Pipe({
    name: 'filterProjects',
    pure: false
})
export class FilterProjectsPipe implements PipeTransform {
    transform(items: MemberProject[], status: string, title: string, code: string, url: string): any[] {
        if (!items) { return []; }

        return items.filter(it => 
            (it.statusCode == status)
            && (!title || it.project.title.toLowerCase().includes(title.toLowerCase()))
            && (!code || it.project.code.toLowerCase().includes(code.toLowerCase()))
            && (!url || it.project.url.toLowerCase().includes(url.toLowerCase()))
        );

    }
}
