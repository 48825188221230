import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { Login } from 'src/app/_models/login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  loginForm: UntypedFormGroup;
  loaded = false;
  model: any = {};
  returnUrl: string;
  passwordMinLength = 6;
  passwordMaxLength = 30;
  myLogin: Login;
  displayMode: string = "email";
  authStateSubscription: any;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private alertify: AlertifyService,
              private authService: AuthService,
              private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    // TODO: implement this.authService.logout();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    
    this.createLoginForm();
    this.loginForm.get('memberId').setValue(null); // Have no idea what the login email is supposed to be
    this.loaded = true;
  }

  ngOnDestroy() {
    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      memberId: [''],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]],
      key: ['']
    });
  }

  login() {
    this.blockUI.start('Loggin in');
    this.authService.login(this.loginForm.getRawValue())
      .subscribe(
        () => {
            // login successful so redirect to return url
            console.log('login successful');
            this.router.navigateByUrl(this.returnUrl);
        },
        error => {
            // login failed so display error
            this.alertify.error(error);
            this.loaded = true;
        }
      ).add(() => {
        this.blockUI.stop();
      });
  }

  resetPassword() {
    const email = this.loginForm.get('email').value;
    if (this.loginForm.get('email').value > '') {
      // tslint:disable-next-line: max-line-length
      this.alertify.confirm('A link to reset your password will be mailed to you, and will expire in 1 hour... are you ready to check your email?', () => {
        this.blockUI.start('Sending password reset link to ' + email);
        this.authService.sendPasswordReset(email).subscribe(() => {
          this.alertify.success('Password reset link set to ' + email);
        }, error => {
          this.alertify.error(error);
        }).add(() => {
          this.blockUI.stop();
        });
      });
    } else {
      this.alertify.error('Please provide your email address');
    }

    return false;
  }

  newAccount() {
    this.displayMode = 'register';
    return false;
  }

  reset($event) {
    this.displayMode = $event;
  }
}
