<div class="modal-header">
  <button type="button" class="fa fa-close" aria-label="Close"
    (click)="cancel()">
  </button> 
  <h4 class="modal-title">{{ this.modalTitle }}</h4> 
</div>
<div class="modal-body">
  <form [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
    <div>
      <label style='width:100%'>
        Code:
        <input formControlName="code" placeholder="" class="form-control">
      </label>
      <div *ngIf="myForm.get('code').invalid && (myForm.get('code').dirty || myForm.get('code').touched)">
        Please enter a code.
      </div>
    </div>
    <div>
      <label style='width:100%'>
        Title:
        <input formControlName="title" placeholder="" class="form-control">
      </label>
      <div *ngIf="myForm.get('title').invalid && (myForm.get('title').dirty || myForm.get('title').touched)">
        Please enter a title.
      </div>
    </div>
    <div>
      <label style='width:100%'>
        Url:
        <input formControlName="url" placeholder="" class="form-control">
      </label>
      <div *ngIf="myForm.get('url').invalid && (myForm.get('url').dirty || myForm.get('url').touched)">
        Please enter a URL.
      </div>
    </div>
    <div>
      <button type="button" (click)="this.cancel();">Cancel</button>
      <button type="submit" [disabled]="myForm.invalid">Save</button>
      <button *ngIf="this.editProject.id > 0 && this.editProject.isActive == true" type="button" (click)="this.activate(false);">Deactivate</button>
      <button *ngIf="this.editProject.id > 0 && this.editProject.isActive == false" type="button" (click)="this.activate(true);">Activate</button>
      <button *ngIf="this.editProject.id > 0 && this.editProject.isActive == true" type="button" (click)="this.showMembers();">Manage Membership</button>
    </div>
  </form>
  
</div>
